<template>
  <div class="error-page">
    <el-row type="flex"
            justify="center"
            align="middle"
    >
      <div class="error-page-container">
        <fa-icon icon="question"
                 class="error-page-icon"
        />
        <h1 class="error-page-title">{{ $t(`route.${$route.meta.title}`) }}</h1>
        <el-button type="info"
                   plain
                   @click="$router.go(-1)"
        >{{ $t('button.return') }}</el-button>
      </div>
    </el-row>
  </div>
</template>

<script>
  export default {
    name: 'Page404',
  };
</script>

<style lang="scss" scoped>
</style>
